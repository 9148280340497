import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons"
import { useMutate } from "../../hooks/useMutate"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import { closeInboxModal, sendToastMessageToInbox } from "../../components/util"
import theme from "../../stylesheets/theme"

const SPACING = "8px"
const LARGE_SPACING = "16px"
const EXTRA_LARGE_SPACING = "32px"
const FONT_SIZE = "14px"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${LARGE_SPACING};
`

const Message = styled.div`
  font-size: ${FONT_SIZE};
  color: ${theme.gray6};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`

const DANGER_TEXT = "#ef4444"

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.1);
  padding: ${SPACING};
  border-radius: 4px;
  font-size: ${FONT_SIZE};
  color: ${DANGER_TEXT};
  gap: ${SPACING};
`

const ActionBar = styled.div`
  box-sizing: border-box;
  border-top: 1px solid ${theme.gray1};
  padding: 8px 12px;
  display: flex;
  justify-content: ${(p) => (p.hasErrors ? "space-between" : "flex-end")};
  align-items: center;
  justify-self: flex-end;

  .box-button-container {
    margin-left: 8px;
  }
`

function DeleteGroup({
  groupId,
  channelId,
  payload,
  signedContext,
  onCancel,
}) {
  const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH

  const deleteGroup = useMutate({
    url: `${SERVER_API_PATH}/api/group`,
    payload,
    signedContext,
    method: "DELETE",
    onSuccess: () => {
      sendToastMessageToInbox({
        message: "Group deleted successfully",
        type: "success",
        options: {
          duration: 15000,
        },
      })
      closeInboxModal()
    },
  })

  const handleDelete = () => {
    deleteGroup.mutate({
      groupId,
      channelId,
    })
  }

  return (
    <Container>
      <div style={{
        flex: 1,
        padding: EXTRA_LARGE_SPACING,
        gap: LARGE_SPACING,
        display: "flex",
        flexDirection: "column",
      }}
      >
        <Message>
          If you would like to delete this group please press the &apos;Delete Group&apos; button below.
        </Message>
        {deleteGroup.error && (
          <ErrorContainer>
            <FontAwesomeIcon icon={faTriangleExclamation} />
            {deleteGroup.error}
          </ErrorContainer>
        )}
      </div>
      <ActionBar>
        <ButtonContainer>
          <NewBoxButton
            style={{
              position: "relative",
              top: "-3px",
            }}
            text="Cancel"
            icon={faXmark}
            onClick={onCancel}
            disabled={deleteGroup.loading}
          />
          <NewBoxButton
            style={{
              position: "relative",
              top: "-3px",
            }}
            danger
            text="Delete Group"
            onClick={handleDelete}
            loading={deleteGroup.loading}
          />
        </ButtonContainer>
      </ActionBar>
    </Container>
  )
}

export default DeleteGroup
