import React, { useEffect, useState } from "react"
import qs from "query-string"
import _ from "lodash"
import styled from "styled-components"
import { isPossiblePhoneNumber } from "react-phone-number-input"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAdd, faTimes, faTrash,
  faTriangleExclamation,
  faSpinner,
  faUsers,
  faImage,
  faXmark,
} from "@fortawesome/free-solid-svg-icons"
import { useUpdateInboxHeader } from "../../hooks/useUpdateInboxHeader"
import theme from "../../stylesheets/theme"
import { ModalBody } from "../../components/Radiate/ThemedModal/ThemedModal"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import CountryCodeSelectField from "../../components/Radiate/CountryCodeSelectField/CountryCodeSelectField"
import { PhoneFieldGroup, CountryCodeWrapper, PhoneWrapper } from "../CreateGroup/CreateGroup"
import TextField from "../../components/Radiate/TextField/TextField"
import {
  UserPill,
  ParticipantsContainer,
  ParticipantsCount,
  NoParticipantsMessage,
} from "./GroupSharedStyles"
import { useMutate } from "../../hooks/useMutate"
import { closeInboxModal, sendToastMessageToInbox } from "../../components/util"
import { useFetch } from "../../hooks/useFetch"
import GroupProfileUploader from "./GroupProfileUploader"
import DeleteGroup from "./DeleteGroup"

const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH
const INBOX_URL = process.env.REACT_APP_INBOX_URL

const SPACING = "8px"
const LARGE_SPACING = "16px"

const FONT_SIZE_TITLE = "14px"

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${SPACING};
  background-color: ${theme.gray02};
`

const GroupMembersTitle = styled.div`
  font-weight: bold;
  font-size: ${FONT_SIZE_TITLE};
`

export const ActionBar = styled.div`
  box-sizing: border-box;
  border-top: 1px solid ${theme.gray1};
  padding: 8px 12px;
  display: flex;
  justify-content: ${(p) => (p.hasErrors ? "space-between" : "flex-end")};
  align-items: center;
  justify-self: flex-end;

  .box-button-container {
    margin-left: 8px;
  }
`

const MaxMembersMessage = styled.div`
  color: ${theme.gray5};
  font-size: ${FONT_SIZE_TITLE};
  margin-top: ${SPACING};
  text-align: center;
  font-style: italic;
`
const DANGER_TEXT = "#ef4444"

const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.1);
  padding: ${SPACING};
  border-radius: 4px;
  font-size: ${FONT_SIZE_TITLE};
  color: ${DANGER_TEXT};
  margin-bottom: ${SPACING};
  word-break: break-word;
  gap: ${SPACING};
`

const OPTION_SELECTED_COLOR = "#dbeafe"

const OptionCard = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING};
  padding: ${LARGE_SPACING};
  border: 1px solid ${({ selected }) => (selected ? theme.primary : theme.gray1)};
  border-radius: 4px;
  cursor: pointer;
  background: ${({ selected }) => (selected ? `${OPTION_SELECTED_COLOR}` : theme.white)};
  
  &:hover {
    background: ${({ selected }) => (selected ? `${OPTION_SELECTED_COLOR}` : theme.gray02)};
  }
`

const OptionIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: ${theme.gray4};
`

const OptionText = styled.div`
  color: ${theme.gray6};
  font-size: ${FONT_SIZE_TITLE};
  font-weight: 500;
`

const UpdateGroup = ({ location }) => {
  const query = qs.parse(location.search)
  const groupId = query.groupId
  const channelId = query.channel
  const [removedParticipants, setRemovedParticipants] = useState([])
  const [signedContext, setSignedContext] = useState(null)
  const [payload, setPayload] = useState(null)
  const [addedPhoneNumbers, setAddedPhoneNumbers] = useState([])
  const [phoneErrors, setPhoneErrors] = useState({})
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedModal, setSelectedModal] = useState(null)

  const getGroup = useFetch({
    url: `${SERVER_API_PATH}/api/group?channelId=${channelId}&groupId=${groupId}`,
    payload,
    signedContext,
    enabled: Boolean(channelId && groupId && payload && signedContext),
    dataPath: null,
  })

  const participants = Object.keys(getGroup?.data?.participants ?? {}).map((p) => p.replace("WC", "+"))

  const updateGroup = useMutate({
    url: `${SERVER_API_PATH}/api/group`,
    payload,
    signedContext,
    method: "PATCH",
    errorPaths: addedPhoneNumbers.length && removedParticipants.length
      ? ["error", "errorMessage", "error.message", "addParticipants.error.message", "removeParticipants.error.message"]
      : ["error", "errorMessage", "error.message"],
    onSuccess: () => {
      sendToastMessageToInbox({
        message: "Group updated successfully",
        type: "success",
        options: {
          duration: 15000,
        },
      })
      closeInboxModal()
    },
  })

  const receiveMessage = (event) => {
    const whitelistedOrigins = INBOX_URL.split("||")
    if (!whitelistedOrigins.includes(event.origin)) {
      return
    }
    if (event?.data) {
      if (!_.isEmpty(event.data?.payload)) {
        setPayload(event.data?.payload)
        setSignedContext(event.data?.signedContext)
      }
    }
  }

  useEffect(() => {
    if (navigator.userAgent !== "ReactSnap") {
      window.addEventListener("message", receiveMessage, false)
      window.parent.postMessage("iframeFinishLoading", "*")
    }
    return () => {
      window.removeEventListener("message", receiveMessage, false)
    }
  }, [])

  useUpdateInboxHeader({ title: "Update WhatsApp Group", isGroupsView: true })

  const validatePhoneNumber = (number, countryCode) => {
    if (!number) return { text: "Please enter a phone number" }
    if (!countryCode) return { text: "Please select a country code for this number" }
    const fullNumber = `+${countryCode}${number}`
    if (!isPossiblePhoneNumber(fullNumber)) {
      return { text: "The phone number you've entered appears to be invalid" }
    }
    return null
  }

  const handlePhoneNumberDelete = (phoneNumber) => {
    setRemovedParticipants((prev) => [...prev, { user: phoneNumber }])
  }

  const handleRemovedParticipantDelete = (user) => {
    setRemovedParticipants((prev) => prev.filter((participant) => participant.user !== user))
  }

  const handleEditGroup = () => {
    const addParticipants = addedPhoneNumbers
      .filter((p) => p.number && p.countryCode?.countryCode)
      .map((p) => ({ user: `+${p.countryCode?.countryCode}${p.number}` }))

    const removeParticipants = removedParticipants.filter((p) => p.user)
    const newErrors = {}
    let hasErrors = false

    addedPhoneNumbers.forEach((phone, index) => {
      const error = validatePhoneNumber(phone.number, phone.countryCode?.countryCode)
      if (error) {
        newErrors[index] = error
        hasErrors = true
      }
    })

    setPhoneErrors(newErrors)
    if (!hasErrors) {
      const updateGroupBody = {
        groupId,
        channelId,
      }

      if (addParticipants.length) {
        updateGroupBody.addParticipants = addParticipants
      }
      if (removeParticipants.length) {
        updateGroupBody.removeParticipants = removeParticipants
      }
      updateGroup.mutate(updateGroupBody)
    }
  }

  const addPhoneNumber = () => {
    setAddedPhoneNumbers([...addedPhoneNumbers, { countryCode: null, number: "" }])
  }

  const completeAddedPhoneNumbers = addedPhoneNumbers
    .filter(({ countryCode, number }) => countryCode?.countryCode && number)

  const addedParticipants = completeAddedPhoneNumbers
    .map(({ countryCode, number }) => ({
      user: `+${countryCode?.countryCode}${number}`,
    }))

  const handleCountryCodeChange = (index, countryCode) => {
    const updatedNumbers = [...addedPhoneNumbers]
    updatedNumbers[index] = { ...updatedNumbers[index], countryCode }
    setAddedPhoneNumbers(updatedNumbers)
  }

  const handlePhoneNumberChange = (index, phoneNumber) => {
    const numericValue = phoneNumber.replace(/[^0-9]/g, "")
    const updatedNumbers = [...addedPhoneNumbers]
    updatedNumbers[index] = { ...updatedNumbers[index], number: numericValue }
    setAddedPhoneNumbers(updatedNumbers)
    setPhoneErrors((prev) => ({
      ...prev,
      [index]: null,
    }))
  }

  const handleAddedParticipantDelete = (index) => {
    // eslint-disable-next-line no-shadow
    setAddedPhoneNumbers((prev) => prev.filter((_, i) => i !== index))
  }

  const currentParticipants = participants.filter(
    (phoneNumber) => !removedParticipants.some((removed) => removed.user === phoneNumber),
  )
  const totalParticipants = participants.length + addedParticipants.length - removedParticipants.length

  if (getGroup.error) {
    return (
      <ErrorMessageContainer style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
      }}
      >
        <FontAwesomeIcon icon={faTriangleExclamation} size="5x" style={{ marginRight: "8px" }} />
        {getGroup.error}
      </ErrorMessageContainer>
    )
  }

  if (getGroup.loading || !signedContext || !payload || !getGroup.data) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "80%",
      }}
      >
        <FontAwesomeIcon icon={faSpinner} size="2x" spin style={{ color: "#f0f0f0" }} />
      </div>
    )
  }

  if (selectedModal === "participants") {
    return (
      <FormContainer>
        <ModalBody style={{
          display: "flex",
          flexDirection: "column",
          gap: LARGE_SPACING,
          padding: LARGE_SPACING,
        }}
        >
          {updateGroup?.error && (
            <ErrorMessageContainer>
              <FontAwesomeIcon icon={faTriangleExclamation} style={{ marginRight: "8px" }} />
              {updateGroup?.error}
            </ErrorMessageContainer>
          )}
          <div style={{ display: "flex", flexDirection: "column", gap: LARGE_SPACING }}>
            <ParticipantsContainer>
              <ParticipantsCount>
                {totalParticipants}
                /8
              </ParticipantsCount>
              <div style={{ display: "flex", flexWrap: "wrap", gap: SPACING }}>
                {(totalParticipants) === 0 ? (
                  <NoParticipantsMessage>No participants.</NoParticipantsMessage>
                ) : (
                  currentParticipants.map((phoneNumber) => (
                    <UserPill
                      key={phoneNumber}
                      phoneNumber={phoneNumber}
                      onPhoneNumberDelete={() => handlePhoneNumberDelete(phoneNumber)}
                    />
                  ))
                )}
                {
                  addedParticipants.map(({ user }, index) => (
                    <UserPill key={user} phoneNumber={user} onPhoneNumberDelete={() => handleAddedParticipantDelete(index)} isAdded />
                  ))
                }
              </div>
              {totalParticipants >= 8 && (
                <MaxMembersMessage>
                  You cannot add more members because you have reached the maximum number allowed.
                </MaxMembersMessage>
              )}
            </ParticipantsContainer>
            {(removedParticipants.length > 0) && (
            <>
              <GroupMembersTitle>Removed participants</GroupMembersTitle>
              <div style={{
                display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: SPACING,
              }}
              >
                {
                  removedParticipants.map(({ user }) => (
                    <UserPill key={user} phoneNumber={user} onPhoneNumberDelete={() => handleRemovedParticipantDelete(user)} isRemoved>
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{ color: theme.dangerText, cursor: "pointer" }}
                        onClick={() => handleRemovedParticipantDelete(user)}
                      />
                    </UserPill>
                  ))
                }
              </div>
            </>
            )}
            {(addedPhoneNumbers.length > 0) && (
              <>
                <GroupMembersTitle>Add participants</GroupMembersTitle>
                {addedPhoneNumbers.map((phone, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <PhoneFieldGroup key={index}>
                    <CountryCodeWrapper>
                      <CountryCodeSelectField
                        value={phone.countryCode}
                        onChange={(countryCode) => handleCountryCodeChange(index, countryCode)}
                        codeOnly
                        generalStyles={{
                          menu: (styles) => ({
                            ...styles,
                            width: "180%",
                            color: "#2e2e2e",
                            zIndex: 999,
                          }),
                          menuList: (styles) => ({
                            ...styles,
                            maxHeight: "200px",
                          }),
                          option: (styles) => ({
                            ...styles,
                            whitespace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            ":hover": {
                              ...styles[":hover"],
                              overflow: "visible",
                            },
                            fontSize: "0.8rem",
                          }),
                          control: (styles) => ({
                            ...styles,
                            borderColor: "hsl(0, 0%, 80%)",
                            fontSize: "0.8rem",
                          }),
                        }}
                      />
                    </CountryCodeWrapper>
                    <PhoneWrapper>
                      <TextField
                        placeholder="Phone number"
                        useInput
                        value={phone.number}
                        error={phoneErrors[index]}
                        onChange={(e) => handlePhoneNumberChange(index, e.target.value)}
                      />
                    </PhoneWrapper>
                  </PhoneFieldGroup>
                ))}
              </>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end", gap: SPACING }}>
              <NewBoxButton
                text="Add Another Participant"
                icon={faAdd}
                onClick={addPhoneNumber}
                disabled={totalParticipants >= 8}
              />
            </div>
          </div>
        </ModalBody>
        <ActionBar>
          <NewBoxButton
            style={{
              position: "relative",
              top: "-3px",
            }}
            text="Cancel"
            icon={faXmark}
            onClick={() => {
              setSelectedModal(null)
              setSelectedOption(null)
            }}
          />
          <NewBoxButton
            style={{
              position: "relative",
              top: "-3px",
            }}
            text="Edit Participants"
            primary
            disabled={(completeAddedPhoneNumbers.length === 0 && removedParticipants.length === 0)}
            loading={updateGroup.loading}
            onClick={handleEditGroup}
          />
        </ActionBar>
      </FormContainer>
    )
  }

  if (selectedModal === "profile-photo") {
    return (
      <GroupProfileUploader
        payload={payload}
        signedContext={signedContext}
        groupId={groupId}
        channelId={channelId}
        onClose={() => {
          setSelectedModal(null)
          setSelectedOption(null)
        }}
      />
    )
  }

  if (selectedModal === "delete-group") {
    return (
      <DeleteGroup
        groupId={groupId}
        channelId={channelId}
        payload={payload}
        signedContext={signedContext}
        onCancel={() => {
          setSelectedModal(null)
          setSelectedOption(null)
        }}
      />
    )
  }

  return (
    <FormContainer>
      <ModalBody style={{
        display: "flex",
        flexDirection: "column",
        gap: LARGE_SPACING,
        padding: LARGE_SPACING,
      }}
      >
        <OptionCard
          onClick={() => setSelectedOption("participants")}
          selected={selectedOption === "participants"}
        >
          <OptionIcon icon={faUsers} />
          <OptionText>Add or remove participants</OptionText>
        </OptionCard>
        <OptionCard
          onClick={() => setSelectedOption("profile-photo")}
          selected={selectedOption === "profile-photo"}
        >
          <OptionIcon icon={faImage} />
          <OptionText>Upload group profile photo</OptionText>
        </OptionCard>
        <OptionCard
          onClick={() => setSelectedOption("delete-group")}
          selected={selectedOption === "delete-group"}
        >
          <OptionIcon icon={faTrash} />
          <OptionText>Delete Group</OptionText>
        </OptionCard>
      </ModalBody>

      <ActionBar>
        <NewBoxButton
          text="Next"
          disabled={!selectedOption}
          style={{
            position: "relative",
            bottom: "3px",
          }}
          onClick={() => {
            if (!selectedOption) return
            setSelectedModal(selectedOption)
            setSelectedOption(null)
          }}
        />
      </ActionBar>
    </FormContainer>
  )
}
export default UpdateGroup
