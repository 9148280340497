import _ from "lodash"
import {
  useEffect, useState, useRef,
} from "react"

function useFetch({
  url,
  // NOTE: use query key to force refetch if needed; although prefer to use the refetch function since it is more declarative. Keep queryKey a primitive string since it's used in the effect
  queryKey = url,
  payload,
  signedContext,
  method = "GET",
  dataPath = "data",
  errorPaths = ["error", "errorMessage", "error.message", "message"],
  fetchOptions = {},
  onFinally = () => {},
  onSuccess = () => {},
  onFailure = () => {},
  enabled = true,
}) {
  const [loading, setLoading] = useState(Boolean(enabled))
  const [error, setError] = useState("")
  const [data, setData] = useState(null)
  const dataFromFirstFetch = useRef(null)

  useEffect(() => {
    if (!enabled) return
    if (!payload || !signedContext || !url || typeof queryKey !== "string") {
      console.log({
        payload, signedContext, url, queryKey,
      })
      throw new Error("payload, signedContext and url are required and queryKey must be a string")
    }
    setLoading(true)
    fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        "X-Woztell-Payload": JSON.stringify(payload),
        "X-Woztell-SignedContext": signedContext,
      },
      ...fetchOptions,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.ok) {
          setError("")
          const apiData = dataPath ? _.get(result, dataPath) : result
          setData(apiData)
          if (!dataFromFirstFetch.current) {
            dataFromFirstFetch.current = apiData
          }
          onSuccess()
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const path of errorPaths) {
            const apiError = _.get(result, path)
            if (apiError && typeof apiError === "string") {
              setError(apiError)
              console.error(apiError)
              onFailure()
              return
            }
            if (apiError instanceof Error) {
              setError(apiError.message)
              console.error(apiError.message)
              onFailure()
              return
            }
          }
          setError("Something went wrong.")
          onFailure()
        }
      })
      .catch((e) => {
        console.error(e?.message || e)
        setError(e?.message || "Something went wrong")
        onFailure()
      })
      .finally(() => {
        setLoading(false)
        onFinally()
      })
  }, [url, queryKey, signedContext, enabled])

  const refetch = () => {
    setLoading(true)
    fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        "X-Woztell-Payload": JSON.stringify(payload),
        "X-Woztell-SignedContext": signedContext,
      },
      ...fetchOptions,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.ok) {
          setError("")
          const apiData = dataPath ? _.get(result, dataPath) : result
          setData(apiData)
          if (!dataFromFirstFetch.current) {
            dataFromFirstFetch.current = apiData
          }
          onSuccess()
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const path of errorPaths) {
            const apiError = _.get(result, path)
            if (apiError && typeof apiError === "string") {
              setError(apiError)
              console.error(apiError)
              onFailure()
              return
            }
            if (apiError instanceof Error) {
              setError(apiError.message)
              console.error(apiError.message)
              onFailure()
              return
            }
          }
          setError("Something went wrong.")
          onFailure()
        }
      })
      .catch((e) => {
        console.error(e?.message || e)
        setError(e?.message || "Something went wrong")
        onFailure()
      })
      .finally(() => {
        setLoading(false)
        onFinally()
      })
  }

  return {
    data,
    error,
    loading,
    dataFromFirstFetch: dataFromFirstFetch.current,
    refetch,
  }
}

export {
  useFetch,
}
