import React, { useRef, useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCloudArrowUp, faSpinner, faTriangleExclamation, faXmark,
} from "@fortawesome/free-solid-svg-icons"
import { useMutate } from "../../hooks/useMutate"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import theme from "../../stylesheets/theme"
import { closeInboxModal, sendToastMessageToInbox } from "../../components/util"

const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH

const SPACING = "8px"
const LARGE_SPACING = "16px"
const EXTRA_LARGE_SPACING = "32px"

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${SPACING};
  background-color: ${theme.gray02};
`

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${LARGE_SPACING};
  padding: ${LARGE_SPACING};
`

const FileInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

const UploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${SPACING};
  background: ${theme.gray02};
  border: 0.5px solid ${theme.gray3};
  padding: 32px 16px;
  color: ${theme.gray5};
  border-radius: 8px;
  cursor: ${({ $isUploading }) => ($isUploading ? "not-allowed" : "pointer")};
  font-size: 14px;
  
  &:hover {
    background: ${({ $isUploading }) => ($isUploading ? theme.gray02 : theme.gray05)};
  }
`

const ImagePreview = styled.img`
  max-width: 200px;
  object-fit: contain;
  border-radius: 8px;
`

const DANGER_TEXT = "#ef4444"

const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.1);
  padding: ${SPACING};
  border-radius: 4px;
  font-size: 14px;
  color: ${DANGER_TEXT};
  gap: ${SPACING};
`

const ActionBar = styled.div`
  box-sizing: border-box;
  border-top: 1px solid ${theme.gray1};
  padding: ${SPACING} ${LARGE_SPACING};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-self: flex-end;
  gap: ${SPACING};
`

const FONT_SIZE = "10px"
const Message = styled.div`
  font-size: ${FONT_SIZE};
  color: ${theme.gray4};
`

function GroupProfileUploader({
  payload, signedContext, groupId, channelId, onClose,
}) {
  const [selectedFile, setSelectedFile] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState(null)
  const fileInputRef = useRef(null)

  const updateGroup = useMutate({
    url: `${SERVER_API_PATH}/api/group`,
    payload,
    signedContext,
    method: "PATCH",
    onSuccess: () => {
      sendToastMessageToInbox({
        message: "Group profile photo updated",
        type: "success",
        options: {
          duration: 15000,
        },
      })
      closeInboxModal()
    },
    onFailure: (err) => {
      setError(err)
    },
    onFinally: () => {
      setIsUploading(false)
    },
  })

  const handleFileSelect = (event) => {
    const file = event?.target?.files?.[0]
    if (!file) return

    if (!file.type.startsWith("image/")) {
      setError("Please select an image file")
      return
    }

    setSelectedFile(file)
    setError(null)

    // Create preview URL
    const reader = new FileReader()
    reader.onloadend = () => {
      setPreviewUrl(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const handleUpload = async () => {
    if (!selectedFile) return

    setIsUploading(true)
    setError(null)

    try {
      const signUrlResponse = await fetch(`${SERVER_API_PATH}/inbox/sign-url`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Woztell-Payload": JSON.stringify(payload),
          "X-Woztell-SignedContext": signedContext,
        },
        body: JSON.stringify({
          fileName: selectedFile.name,
          fileType: selectedFile.type,
        }),
      })

      const signUrlResult = await signUrlResponse.json()
      if (!signUrlResult?.ok || !signUrlResult?.url || !signUrlResult?.fields) {
        throw new Error("Failed to get signed URL")
      }

      const form = new FormData()
      Object.entries(signUrlResult.fields).forEach(([key, value]) => {
        form.append(key, value)
      })
      form.append("file", selectedFile)

      const uploadResponse = await fetch(signUrlResult.url, {
        method: "POST",
        body: form,
      })

      if (uploadResponse.status !== 204) {
        throw new Error("Failed to upload file")
      }

      const split = signUrlResult.fields.key.split("/")
      const encodedSplit = split.map((s) => encodeURIComponent(s))
      const finalUrl = `${signUrlResult.url}/${encodedSplit.join("/")}`

      updateGroup.mutate({
        groupId,
        channelId,
        // eslint-disable-next-line camelcase
        profile_picture_file: {
          url: finalUrl,
        },
      })
    } catch (err) {
      setError(err.message)
      setIsUploading(false)
    }
  }

  return (
    <FormContainer>
      <ModalBody>
        <FileInput
          type="file"
          accept="image/jpeg"
          ref={fileInputRef}
          onChange={handleFileSelect}
        />

        {error && (
          <ErrorMessage>
            <FontAwesomeIcon icon={faTriangleExclamation} />
            {error}
          </ErrorMessage>
        )}

        {previewUrl ? (
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: EXTRA_LARGE_SPACING,
          }}
          >
            <ImagePreview src={previewUrl} alt="Preview" />
            <NewBoxButton
              text="Choose Different Image"
              onClick={() => fileInputRef.current?.click()}
              disabled={isUploading}
            />
          </div>
        ) : (
          <UploadButton
            $isUploading={isUploading}
            onClick={() => !isUploading && fileInputRef.current?.click()}
          >
            <FontAwesomeIcon
              icon={isUploading ? faSpinner : faCloudArrowUp}
              size="4x"
              spin={isUploading}
            />
            <div>{isUploading ? "Uploading..." : "Click to upload group profile picture"}</div>
            <Message>File must be in JPEG image</Message>
          </UploadButton>
        )}
      </ModalBody>

      <ActionBar
        style={{
          position: "relative",
          bottom: "3px",
        }}
      >
        <NewBoxButton
          icon={faXmark}
          text="Cancel"
          onClick={onClose}
          disabled={isUploading}
        />
        <NewBoxButton
          text="Upload"
          primary
          onClick={handleUpload}
          disabled={!selectedFile || isUploading}
          loading={isUploading}
        />
      </ActionBar>
    </FormContainer>
  )
}

export default GroupProfileUploader
