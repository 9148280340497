import _ from "lodash"
import { useState, useEffect } from "react"
import variables from "../stylesheets/var.scss"

export function useScript(src) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "idle")
  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus("idle")
        return
      }
      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`)
      if (!script) {
        // Create script
        script = document.createElement("script")
        script.src = src
        script.async = true
        script.setAttribute("data-status", "loading")
        // Add script to document body
        document.body.appendChild(script)
        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event) => {
          script.setAttribute(
            "data-status",
            event.type === "load" ? "ready" : "error",
          )
        }
        script.addEventListener("load", setAttributeFromEvent)
        script.addEventListener("error", setAttributeFromEvent)
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute("data-status"))
      }
      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event) => {
        setStatus(event.type === "load" ? "ready" : "error")
      }
      // Add event listeners
      script.addEventListener("load", setStateFromEvent)
      script.addEventListener("error", setStateFromEvent)
      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener("load", setStateFromEvent)
          script.removeEventListener("error", setStateFromEvent)
        }
      }
    },
    [src], // Only re-run effect if script src changes
  )
  return status
}

export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const newHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null
}

export function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}

export function randomString(length) {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  let result = ""
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

export function addRandomKeyToArray(array) {
  return (array || []).map((o) => {
    const clone = _.cloneDeep(o)
    clone.__key__ = randomString(10)
    return clone
  })
}

export function removeRandomKeyFromArray(array) {
  return array.map((o) => _.omit(o, ["__key__"]))
}

export function normalizeText(text) {
  let newText = text.replace(/[\b]/g, "")
  newText = newText.replace(/\\r\\n|\\r/g, "\n")
  return newText
}

export function isJSONString(string) {
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

export function xorStrings(a, b) {
  const aInDecimalArray = a.split("").map((c) => c.charCodeAt(0))
  const bInDecimalArray = b.split("").map((c) => c.charCodeAt(0))
  const resultArray = []

  if (aInDecimalArray.length > bInDecimalArray.length) {
    for (let i = 0; i < bInDecimalArray.length; i++) {
      resultArray.push(aInDecimalArray[i] ^ bInDecimalArray[i])
    }
  } else {
    for (let i = 0; i < aInDecimalArray.length; i++) {
      resultArray.push(aInDecimalArray[i] ^ bInDecimalArray[i])
    }
  }

  const resultArrayInChar = resultArray.map((charCode) => String.fromCharCode(charCode))

  const result = resultArrayInChar.join("")
  return result
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export function trimText(text, limit) {
  let modText = text
  if (text?.length >= limit) {
    modText = `${text.slice(0, limit - 1)}...`
  }
  return modText
}

export function reverseTrimText(text, limit) {
  let modText = text
  if (text?.length >= limit) {
    modText = `...${text.slice(-limit)}`
  }
  return modText
}

export const categoryOptions = [
  {
    label: "Automotive",
    value: "AUTO",
  }, {
    label: "Beauty, cosmetic & personal care",
    value: "BEAUTY",
  }, {
    label: "Apparel & clothing",
    value: "APPAREL",
  }, {
    label: "Education",
    value: "EDU",
  }, {
    label: "Arts & entertainment",
    value: "ENTERTAIN",
  }, {
    label: "Event Planner",
    value: "EVENT_PLAN",
  }, {
    label: "Finance",
    value: "FINANCE",
  }, {
    label: "Grocery Store",
    value: "GROCERY",
  }, {
    label: "Public & Government Service",
    value: "GOVT",
  }, {
    label: "Hotel",
    value: "HOTEL",
  }, {
    label: "Medical & Health",
    value: "HEALTH",
  }, {
    label: "Nonprofit organization",
    value: "NONPROFIT",
  }, {
    label: "Professional Service",
    value: "PROF_SERVICES",
  }, {
    label: "Shopping & Retail",
    value: "RETAIL",
  }, {
    label: "Travel & Transportation",
    value: "TRAVEL",
  }, {
    label: "Restaurant",
    value: "RESTAURANT",
  }, {
    label: "Other",
    value: "OTHER",
  },
]

export const localeList = [
  {
    value: "af_ZA",
    text: "Afrikaans (South Africa)",
  },
  {
    value: "ar_AR",
    text: "Arabic",
  },
  {
    value: "as_IN",
    text: "Assamese (India)",
  },
  {
    value: "az_AZ",
    text: "Azerbaijani (Azerbaijan)",
  },
  {
    value: "be_BY",
    text: "Belarusian (Belarus)",
  },
  {
    value: "bg_BG",
    text: "Bulgarian (Bulgaria)",
  },
  {
    value: "bn_IN",
    text: "Bangla (India)",
  },
  {
    value: "br_FR",
    text: "Breton (France)",
  },
  {
    value: "bs_BA",
    text: "Bosnian (Bosnia & Herzegovina)\t",
  },
  {
    value: "ca_ES",
    text: "Catalan (Spain)",
  },
  {
    value: "cb_IQ",
    text: "Kurdish (Iraq)",
  },
  {
    value: "co_FR",
    text: "Corsican (France)",
  },
  {
    value: "cs_CZ",
    text: "Czech (Czechia)",
  },
  {
    value: "cx_PH",
    text: "Cebuano (Philippines)",
  },
  {
    value: "cy_GB",
    text: "Welsh (United Kingdom)",
  },
  {
    value: "da_DK",
    text: "Danish (Denmark)",
  },
  {
    value: "de_DE",
    text: "German (Germany)",
  },
  {
    value: "el_GR",
    text: "Greek (Greece)",
  },
  {
    value: "en*",
    text: "English (All)",
    type: "group",
  },
  {
    value: "en_GB",
    text: "English (United Kingdom)",
    type: "child",
  },
  {
    value: "en_UD",
    text: "English (Upside Down)",
    type: "child",
  },
  {
    value: "en_US",
    text: "English (United States)",
    type: "child",
  },
  {
    value: "es_ES",
    text: "Spanish (Spain)",
  },
  {
    value: "es_LA",
    text: "Spanish",
  },
  {
    value: "et_EE",
    text: "Estonian (Estonia)",
  },
  {
    value: "eu_ES",
    text: "Basque (Spain)",
  },
  {
    value: "fa_IR",
    text: "Persian (Iran)",
  },
  {
    value: "ff_NG",
    text: "Fulah (Nigeria)",
  },
  {
    value: "fi_FI",
    text: "Finnish (Finland)",
  },
  {
    value: "fo_FO",
    text: "Faroese (Faroe Islands)",
  },
  {
    value: "fr*",
    text: "French (All)",
    type: "group",
  },
  {
    value: "fr_CA",
    text: "French (Canada)",
    type: "child",
  },
  {
    value: "fr_FR",
    text: "French (France)",
    type: "child",
  },
  {
    value: "fy_NL",
    text: "Western Frisian (Netherlands)",
  },
  {
    value: "ga_IE",
    text: "Irish (Ireland)",
  },
  {
    value: "gl_ES",
    text: "Galician (Spain)",
  },
  {
    value: "gn_PY",
    text: "Guaraní",
  },
  {
    value: "gu_IN",
    text: "Gujarati (India)",
  },
  {
    value: "ha_NG",
    text: "Hausa (Nigeria)",
  },
  {
    value: "he_IL",
    text: "Hebrew (Israel)",
  },
  {
    value: "hi_IN",
    text: "Hindi (India)",
  },
  {
    value: "hr_HR",
    text: "Croatian (Croatia)",
  },
  {
    value: "hu_HU",
    text: "Hungarian (Hungary)",
  },
  {
    value: "hy_AM",
    text: "Armenian (Armenia)",
  },
  {
    value: "is_IS",
    text: "Icelandic (Iceland)",
  },
  {
    value: "id_ID",
    text: "Indonesia",
  },
  {
    value: "it_IT",
    text: "Italian (Italy)",
  },
  {
    value: "ja*",
    text: "Japanese (All)",
    type: "group",
  },
  {
    value: "ja_JP",
    text: "Japanese (Japan)",
    type: "child",
  },
  {
    value: "ja_KS",
    text: "Japanese (Kansai)",
    type: "child",
  },
  {
    value: "jv_ID",
    text: "Javanese",
  },
  {
    value: "ka_GE",
    text: "Georgian (Georgia)",
  },
  {
    value: "kk_KZ",
    text: "Kazakh (Kazakhstan)",
  },
  {
    value: "km_KH",
    text: "Khmer (Cambodia)",
  },
  {
    value: "kn_IN",
    text: "Kannada (India)",
  },
  {
    value: "ko_KR",
    text: "Korean (South Korea)",
  },
  {
    value: "ku_TR",
    text: "Kurdish",
  },
  {
    value: "lt_LT",
    text: "Lithuanian (Lithuania)",
  },
  {
    value: "lv_LV",
    text: "Latvian (Latvia)",
  },
  {
    value: "mg_MG",
    text: "Malagasy (Madagascar)",
  },
  {
    value: "mk_MK",
    text: "Macedonian (Macedonia)",
  },
  {
    value: "ml_IN",
    text: "Malayalam (India)",
  },
  {
    value: "mn_MN",
    text: "Mongolian (Mongolia)",
  },
  {
    value: "mr_IN",
    text: "Marathi (India)",
  },
  {
    value: "ms_MY",
    text: "Malay (Malaysia)",
  },
  {
    value: "mt_MT",
    text: "Maltese (Malta)",
  },
  {
    value: "my_MM",
    text: "Burmese (Myanmar [Burma])",
  },
  {
    value: "nb_NO",
    text: "Norwegian Bokmål (Norway)",
  },
  {
    value: "ne_NP",
    text: "Nepali (Nepal)",
  },
  {
    value: "nl*",
    text: "Dutch (All)",
    type: "group",
  },
  {
    value: "nl_BE",
    text: "Dutch (Belgium)",
    type: "child",
  },
  {
    value: "nl_NL",
    text: "Dutch (Netherlands)",
    type: "child",
  },
  {
    value: "nn_NO",
    text: "Norwegian Nynorsk (Norway)",
  },
  {
    value: "or_IN",
    text: "Odia (India)",
  },
  {
    value: "pa_IN",
    text: "Punjabi (India)",
  },
  {
    value: "pl_PL",
    text: "Polish (Poland)",
  },
  {
    value: "ps_AF",
    text: "Pashto (Afghanistan)",
  },
  {
    value: "pt*",
    text: "Portuguese (All)",
    type: "group",
  },
  {
    value: "pt_BR",
    text: "Portuguese (Brazil)",
    type: "child",
  },
  {
    value: "pt_PT",
    text: "Portuguese (Portugal)",
    type: "child",
  },
  {
    value: "qz_MM",
    text: "Burmese (Zawgyi)",
  },
  {
    value: "ro_RO",
    text: "Romanian (Romania)",
  },
  {
    value: "ru_RU",
    text: "Russian (Russia)",
  },
  {
    value: "rw_RW",
    text: "Kinyarwanda (Rwanda)",
  },
  {
    value: "sc_IT",
    text: "Sardinian (Italy)",
  },
  {
    value: "si_LK",
    text: "Sinhala (Sri Lanka)",
  },
  {
    value: "sk_SK",
    text: "Slovak (Slovakia)",
  },
  {
    value: "sl_SI",
    text: "Slovenian (Slovenia)",
  },
  {
    value: "so_SO",
    text: "Somali (Somalia)",
  },
  {
    value: "sq_AL",
    text: "Albanian (Albania)",
  },
  {
    value: "sr_RS",
    text: "Serbian (Serbia)",
  },
  {
    value: "sv_SE",
    text: "Swedish (Sweden)",
  },
  {
    value: "sw_KE",
    text: "Swahili (Kenya)",
  },
  {
    value: "sz_PL",
    text: "Silesian (Poland)",
  },
  {
    value: "ta_IN",
    text: "Tamil (India)",
  },
  {
    value: "te_IN",
    text: "Telugu (India)",
  },
  {
    value: "tg_TJ",
    text: "Tajik (Tajikistan)",
  },
  {
    value: "th_TH",
    text: "Thai (Thailand)",
  },
  {
    value: "tl_PH",
    text: "Filipino",
  },
  {
    value: "tr_TR",
    text: "Turkish (Turkey)",
  },
  {
    value: "tz_MA",
    text: "Tamazight (Morocco)",
  },
  {
    value: "uk_UA",
    text: "Ukrainian (Ukraine)",
  },
  {
    value: "ur_PK",
    text: "Urdu (Pakistan)",
  },
  {
    value: "uz_UZ",
    text: "Uzbek (Uzbekistan)",
  },
  {
    value: "vi_VN",
    text: "Vietnamese (Vietnam)",
  },
  {
    value: "zh*",
    text: "Chinese (All)",
    type: "group",
  },
  {
    value: "zh_CN",
    text: "Simplified Chinese (China)",
    type: "child",
  },
  {
    value: "zh_HK",
    text: "Traditional Chinese (Hong Kong)",
    type: "child",
  },
  {
    value: "zh_TW",
    text: "Traditional Chinese (Taiwan)",
    type: "child",
  },
]

export const languageOptions = [{
  label: "Afrikaans",
  value: "af",
}, {
  label: "Albanian",
  value: "sq",
}, {
  label: "Arabic",
  value: "ar",
}, {
  label: "Azerbaijani",
  value: "az",
}, {
  label: "Bengali",
  value: "bn",
}, {
  label: "Bulgarian",
  value: "bg",
}, {
  label: "Catalan",
  value: "ca",
}, {
  label: "Chinese (CHN)",
  value: "zh_CN",
}, {
  label: "Chinese (HKG)",
  value: "zh_HK",
}, {
  label: "Chinese (TAI)",
  value: "zh_TW",
}, {
  label: "Croatian",
  value: "hr",
}, {
  label: "Czech",
  value: "cs",
}, {
  label: "Danish",
  value: "da",
}, {
  label: "Dutch",
  value: "nl",
}, {
  label: "English",
  value: "en",
}, {
  label: "English (UK)",
  value: "en_GB",
}, {
  label: "English (US)",
  value: "en_US",
}, {
  label: "Estonian",
  value: "et",
}, {
  label: "Filipino",
  value: "fil",
}, {
  label: "Finnish",
  value: "fi",
}, {
  label: "French",
  value: "fr",
}, {
  label: "German",
  value: "de",
}, {
  label: "Greek",
  value: "el",
}, {
  label: "Gujarati",
  value: "gu",
}, {
  label: "Hebrew",
  value: "he",
}, {
  label: "Hindi",
  value: "hi",
}, {
  label: "Hungarian",
  value: "hu",
}, {
  label: "Indonesian",
  value: "id",
}, {
  label: "Irish",
  value: "ga",
}, {
  label: "Italian",
  value: "it",
}, {
  label: "Japanese",
  value: "ja",
}, {
  label: "Kannada",
  value: "kn",
}, {
  label: "Kazakh",
  value: "kk",
}, {
  label: "Korean",
  value: "ko",
}, {
  label: "Lao",
  value: "lo",
}, {
  label: "Latvian",
  value: "lv",
}, {
  label: "Lithuanian",
  value: "lt",
}, {
  label: "Macedonian",
  value: "mk",
}, {
  label: "Malay",
  value: "ms",
}, {
  label: "Marathi",
  value: "mr",
}, {
  label: "Norwegian",
  value: "nb",
}, {
  label: "Persian",
  value: "fa",
}, {
  label: "Polish",
  value: "pl",
}, {
  label: "Portuguese (BR)",
  value: "pt_BR",
}, {
  label: "Portuguese (POR)",
  value: "pt_PT",
}, {
  label: "Punjabi",
  value: "pa",
}, {
  label: "Romanian",
  value: "ro",
}, {
  label: "Russian",
  value: "ru",
}, {
  label: "Serbian",
  value: "sr",
}, {
  label: "Slovak",
  value: "sk",
}, {
  label: "Slovenian",
  value: "sl",
}, {
  label: "Spanish",
  value: "es",
}, {
  label: "Spanish (ARG)",
  value: "es_AR",
}, {
  label: "Spanish (SPA)",
  value: "es_ES",
}, {
  label: "Spanish (MEX)",
  value: "es_MX",
}, {
  label: "Swahili",
  value: "sw",
}, {
  label: "Swedish",
  value: "sv",
}, {
  label: "Tamil",
  value: "ta",
}, {
  label: "Telugu",
  value: "te",
}, {
  label: "Thai",
  value: "th",
}, {
  label: "Turkish",
  value: "tr",
}, {
  label: "Ukrainian",
  value: "uk",
}, {
  label: "Urdu",
  value: "ur",
}, {
  label: "Uzbek",
  value: "uz",
}, {
  label: "Vietnamese",
  value: "vi",
}]

export const rtlLanguages = new Set(["ar", "he", "cb_IQ", "fa", "ur"])

export function timestampNormalizer(timestamp) {
  const split = `${timestamp}`.split(".")
  let result = timestamp
  if (split.length >= 2) {
    if (split[1].length >= 3) {
      return parseFloat(timestamp)
    }
  }
  if (typeof timestamp === "string") {
    result = parseInt(timestamp, 10)
  }
  if (result && result.toString().length <= 10) {
    result *= 1000
  }

  return result
}

export const pureLocaleList = [
  {
    value: "af_ZA",
    text: "Afrikaans (South Africa)",
  },
  {
    value: "ar_AR",
    text: "Arabic",
  },
  {
    value: "as_IN",
    text: "Assamese (India)",
  },
  {
    value: "az_AZ",
    text: "Azerbaijani (Azerbaijan)",
  },
  {
    value: "be_BY",
    text: "Belarusian (Belarus)",
  },
  {
    value: "bg_BG",
    text: "Bulgarian (Bulgaria)",
  },
  {
    value: "bn_IN",
    text: "Bangla (India)",
  },
  {
    value: "br_FR",
    text: "Breton (France)",
  },
  {
    value: "bs_BA",
    text: "Bosnian (Bosnia & Herzegovina)\t",
  },
  {
    value: "ca_ES",
    text: "Catalan (Spain)",
  },
  {
    value: "cb_IQ",
    text: "Kurdish (Iraq)",
  },
  {
    value: "co_FR",
    text: "Corsican (France)",
  },
  {
    value: "cs_CZ",
    text: "Czech (Czechia)",
  },
  {
    value: "cx_PH",
    text: "Cebuano (Philippines)",
  },
  {
    value: "cy_GB",
    text: "Welsh (United Kingdom)",
  },
  {
    value: "da_DK",
    text: "Danish (Denmark)",
  },
  {
    value: "de_DE",
    text: "German (Germany)",
  },
  {
    value: "el_GR",
    text: "Greek (Greece)",
  },
  {
    value: "en_GB",
    text: "English (United Kingdom)",
  },
  {
    value: "en_UD",
    text: "English (Upside Down)",
  },
  {
    value: "en_US",
    text: "English (United States)",
  },
  {
    value: "es_ES",
    text: "Spanish (Spain)",
  },
  {
    value: "es_LA",
    text: "Spanish",
  },
  {
    value: "et_EE",
    text: "Estonian (Estonia)",
  },
  {
    value: "eu_ES",
    text: "Basque (Spain)",
  },
  {
    value: "fa_IR",
    text: "Persian (Iran)",
  },
  {
    value: "ff_NG",
    text: "Fulah (Nigeria)",
  },
  {
    value: "fi_FI",
    text: "Finnish (Finland)",
  },
  {
    value: "fo_FO",
    text: "Faroese (Faroe Islands)",
  },
  {
    value: "fr_CA",
    text: "French (Canada)",
  },
  {
    value: "fr_FR",
    text: "French (France)",
  },
  {
    value: "fy_NL",
    text: "Western Frisian (Netherlands)",
  },
  {
    value: "id_ID",
    text: "Indonesia",
  },
  {
    value: "ga_IE",
    text: "Irish (Ireland)",
  },
  {
    value: "gl_ES",
    text: "Galician (Spain)",
  },
  {
    value: "gn_PY",
    text: "Guaraní",
  },
  {
    value: "gu_IN",
    text: "Gujarati (India)",
  },
  {
    value: "ha_NG",
    text: "Hausa (Nigeria)",
  },
  {
    value: "he_IL",
    text: "Hebrew (Israel)",
  },
  {
    value: "hi_IN",
    text: "Hindi (India)",
  },
  {
    value: "hr_HR",
    text: "Croatian (Croatia)",
  },
  {
    value: "hu_HU",
    text: "Hungarian (Hungary)",
  },
  {
    value: "hy_AM",
    text: "Armenian (Armenia)",
  },
  {
    value: "is_IS",
    text: "Icelandic (Iceland)",
  },
  {
    value: "it_IT",
    text: "Italian (Italy)",
  },
  {
    value: "ja_JP",
    text: "Japanese (Japan)",
  },
  {
    value: "ja_KS",
    text: "Japanese (Kansai)",
  },
  {
    value: "jv_ID",
    text: "Javanese",
  },
  {
    value: "ka_GE",
    text: "Georgian (Georgia)",
  },
  {
    value: "kk_KZ",
    text: "Kazakh (Kazakhstan)",
  },
  {
    value: "km_KH",
    text: "Khmer (Cambodia)",
  },
  {
    value: "kn_IN",
    text: "Kannada (India)",
  },
  {
    value: "ko_KR",
    text: "Korean (South Korea)",
  },
  {
    value: "ku_TR",
    text: "Kurdish",
  },
  {
    value: "lt_LT",
    text: "Lithuanian (Lithuania)",
  },
  {
    value: "lv_LV",
    text: "Latvian (Latvia)",
  },
  {
    value: "mg_MG",
    text: "Malagasy (Madagascar)",
  },
  {
    value: "mk_MK",
    text: "Macedonian (Macedonia)",
  },
  {
    value: "ml_IN",
    text: "Malayalam (India)",
  },
  {
    value: "mn_MN",
    text: "Mongolian (Mongolia)",
  },
  {
    value: "mr_IN",
    text: "Marathi (India)",
  },
  {
    value: "ms_MY",
    text: "Malay (Malaysia)",
  },
  {
    value: "mt_MT",
    text: "Maltese (Malta)",
  },
  {
    value: "my_MM",
    text: "Burmese (Myanmar [Burma])",
  },
  {
    value: "nb_NO",
    text: "Norwegian Bokmål (Norway)",
  },
  {
    value: "ne_NP",
    text: "Nepali (Nepal)",
  },
  {
    value: "nl_BE",
    text: "Dutch (Belgium)",
  },
  {
    value: "nl_NL",
    text: "Dutch (Netherlands)",
  },
  {
    value: "nn_NO",
    text: "Norwegian Nynorsk (Norway)",
  },
  {
    value: "or_IN",
    text: "Odia (India)",
  },
  {
    value: "pa_IN",
    text: "Punjabi (India)",
  },
  {
    value: "pl_PL",
    text: "Polish (Poland)",
  },
  {
    value: "ps_AF",
    text: "Pashto (Afghanistan)",
  },
  {
    value: "pt_BR",
    text: "Portuguese (Brazil)",
  },
  {
    value: "pt_PT",
    text: "Portuguese (Portugal)",
  },
  {
    value: "qz_MM",
    text: "Burmese (Zawgyi)",
  },
  {
    value: "ro_RO",
    text: "Romanian (Romania)",
  },
  {
    value: "ru_RU",
    text: "Russian (Russia)",
  },
  {
    value: "rw_RW",
    text: "Kinyarwanda (Rwanda)",
  },
  {
    value: "sc_IT",
    text: "Sardinian (Italy)",
  },
  {
    value: "si_LK",
    text: "Sinhala (Sri Lanka)",
  },
  {
    value: "sk_SK",
    text: "Slovak (Slovakia)",
  },
  {
    value: "sl_SI",
    text: "Slovenian (Slovenia)",
  },
  {
    value: "so_SO",
    text: "Somali (Somalia)",
  },
  {
    value: "sq_AL",
    text: "Albanian (Albania)",
  },
  {
    value: "sr_RS",
    text: "Serbian (Serbia)",
  },
  {
    value: "sv_SE",
    text: "Swedish (Sweden)",
  },
  {
    value: "sw_KE",
    text: "Swahili (Kenya)",
  },
  {
    value: "sz_PL",
    text: "Silesian (Poland)",
  },
  {
    value: "ta_IN",
    text: "Tamil (India)",
  },
  {
    value: "te_IN",
    text: "Telugu (India)",
  },
  {
    value: "tg_TJ",
    text: "Tajik (Tajikistan)",
  },
  {
    value: "th_TH",
    text: "Thai (Thailand)",
  },
  {
    value: "tl_PH",
    text: "Filipino",
  },
  {
    value: "tr_TR",
    text: "Turkish (Turkey)",
  },
  {
    value: "tz_MA",
    text: "Tamazight (Morocco)",
  },
  {
    value: "uk_UA",
    text: "Ukrainian (Ukraine)",
  },
  {
    value: "ur_PK",
    text: "Urdu (Pakistan)",
  },
  {
    value: "uz_UZ",
    text: "Uzbek (Uzbekistan)",
  },
  {
    value: "vi_VN",
    text: "Vietnamese (Vietnam)",
  },
  {
    value: "zh_CN",
    text: "Simplified Chinese (China)",
  },
  {
    value: "zh_HK",
    text: "Traditional Chinese (Hong Kong)",
  },
  {
    value: "zh_TW",
    text: "Traditional Chinese (Taiwan)",
  },
]

export const countryCodeList = [{
  label: "Afghanistan (+93)",
  value: "AF",
}, {
  label: "Albania (+355)",
  value: "AL",
}, {
  label: "Algeria (+213)",
  value: "DZ",
}, {
  label: "American Samoa (+1)",
  value: "AS",
}, {
  label: "Andorra (+376)",
  value: "AD",
}, {
  label: "Angola (+244)",
  value: "AO",
}, {
  label: "Anguilla (+1)",
  value: "AI",
}, {
  label: "Antigua (+1)",
  value: "AG",
}, {
  label: "Argentina (+54)",
  value: "AR",
}, {
  label: "Armenia (+374)",
  value: "AM",
}, {
  label: "Aruba (+297)",
  value: "AW",
}, {
  label: "Australia (+61)",
  value: "AU",
}, {
  label: "Austria (+43)",
  value: "AT",
}, {
  label: "Azerbaijan (+994)",
  value: "AZ",
}, {
  label: "Bahrain (+973)",
  value: "BH",
}, {
  label: "Bangladesh (+880)",
  value: "BD",
}, {
  label: "Barbados (+1)",
  value: "BB",
}, {
  label: "Belarus (+375)",
  value: "BY",
}, {
  label: "Belgium (+32)",
  value: "BE",
}, {
  label: "Belize (+501)",
  value: "BZ",
}, {
  label: "Benin (+229)",
  value: "BJ",
}, {
  label: "Bermuda (+1)",
  value: "BM",
}, {
  label: "Bhutan (+975)",
  value: "BT",
}, {
  label: "Bolivia (+591)",
  value: "BO",
}, {
  label: "Bonaire, Sint Eustatius and Saba (+599)",
  value: "BQ",
}, {
  label: "Bosnia and Herzegovina (+387)",
  value: "BA",
}, {
  label: "Botswana (+267)",
  value: "BW",
}, {
  label: "Brazil (+55)",
  value: "BR",
}, {
  label: "British Indian Ocean Territory (+246)",
  value: "IO",
}, {
  label: "British Virgin Islands (+1)",
  value: "VG",
}, {
  label: "Brunei (+673)",
  value: "BN",
}, {
  label: "Bulgaria (+359)",
  value: "BG",
}, {
  label: "Burkina Faso (+226)",
  value: "BF",
}, {
  label: "Burundi (+257)",
  value: "BI",
}, {
  label: "Cambodia (+855)",
  value: "KH",
}, {
  label: "Cameroon (+237)",
  value: "CM",
}, {
  label: "Canada (+1)",
  value: "CA",
}, {
  label: "Cape Verde (+238)",
  value: "CV",
}, {
  label: "Cayman Islands (+1)",
  value: "KY",
}, {
  label: "Central African Republic (+236)",
  value: "CF",
}, {
  label: "Chad (+235)",
  value: "TD",
}, {
  label: "Chile (+56)",
  value: "CL",
}, {
  label: "China (+86)",
  value: "CN",
}, {
  label: "Colombia (+57)",
  value: "CO",
}, {
  label: "Comoros (+269)",
  value: "KM",
}, {
  label: "Cook Islands (+682)",
  value: "CK",
}, {
  label: "Costa Rica (+506)",
  value: "CR",
}, {
  label: "Côte d'Ivoire (+255)",
  value: "CI",
}, {
  label: "Croatia (+385)",
  value: "HR",
}, {
  label: "Cuba (+53)",
  value: "CU",
}, {
  label: "Curaçao (+599)",
  value: "CW",
}, {
  label: "Cyprus (+357)",
  value: "CY",
}, {
  label: "Czech Republic (+420)",
  value: "CZ",
}, {
  label: "Democratic Republic of the Congo (+243)",
  value: "CD",
}, {
  label: "Demark (+45)",
  value: "DK",
}, {
  label: "Djibouti (+253)",
  value: "DJ",
}, {
  label: "Dominica (+1)",
  value: "DM",
}, {
  label: "Dominican Republic (+1)",
  value: "DO",
}, {
  label: "Ecuador (+593)",
  value: "EC",
}, {
  label: "Egypt (+20)",
  value: "EG",
}, {
  label: "El Salvador (+503)",
  value: "SV",
}, {
  label: "Equatorial Guinea (+240)",
  value: "GQ",
}, {
  label: "Eritrea (+291)",
  value: "ER",
}, {
  label: "Estonia (+372)",
  value: "EE",
}, {
  label: "Ethiopia (+251)",
  value: "ET",
}, {
  label: "Falkland Islands (+500)",
  value: "FK",
}, {
  label: "Faroe Islands (+298)",
  value: "FO",
}, {
  label: "Federated States of Micronesia (+691)",
  value: "FM",
}, {
  label: "Fiji (+679)",
  value: "FJ",
}, {
  label: "Finland (+358)",
  value: "FI",
}, {
  label: "France (+33)",
  value: "FR",
}, {
  label: "French Guiana (+594)",
  value: "GF",
}, {
  label: "French Polynesia (+689)",
  value: "PF",
}, {
  label: "Gabon (+241)",
  value: "GA",
}, {
  label: "Georgia (+995)",
  value: "GE",
}, {
  label: "Germany (+49)",
  value: "DE",
}, {
  label: "Ghana (+233)",
  value: "GH",
}, {
  label: "Gibraltar (+350)",
  value: "GI",
}, {
  label: "Greece (+30)",
  value: "GR",
}, {
  label: "Greenland (+299)",
  value: "GL",
}, {
  label: "Grenada (+1)",
  value: "GD",
}, {
  label: "Guadeloupe (+502)",
  value: "GP",
}, {
  label: "Guam (+1)",
  value: "GU",
}, {
  label: "Guatemala (+502)",
  value: "GT",
}, {
  label: "Guernsey (+44)",
  value: "GG",
}, {
  label: "Guinea (+244)",
  value: "GN",
}, {
  label: "Guinea-Bissau (+245)",
  value: "GW",
}, {
  label: "Guyana (+592)",
  value: "GY",
}, {
  label: "Haiti (+509)",
  value: "HT",
}, {
  label: "Honduras (+504)",
  value: "HN",
}, {
  label: "Hong Kong (+852)",
  value: "HK",
}, {
  label: "Hungary (+36)",
  value: "HU",
}, {
  label: "Iceland (+354)",
  value: "IS",
}, {
  label: "India (+91)",
  value: "IN",
}, {
  label: "Indonesia (+62)",
  value: "ID",
}, {
  label: "Iran (+98)",
  value: "IR",
}, {
  label: "Iraq (+964)",
  value: "IQ",
}, {
  label: "Ireland (+353)",
  value: "IE",
}, {
  label: "Isle Of Man (+44)",
  value: "IM",
}, {
  label: "Israel (+972)",
  value: "IL",
}, {
  label: "Italy (+39)",
  value: "IT",
}, {
  label: "Jamaica (+1)",
  value: "JM",
}, {
  label: "Japan (+81)",
  value: "JP",
}, {
  label: "Jersey (+44)",
  value: "JE",
}, {
  label: "Jordan (+962)",
  value: "JO",
}, {
  label: "Kazakhstan (+7)",
  value: "KZ",
}, {
  label: "Kenya (+254)",
  value: "KE",
}, {
  label: "Kiribati (+686)",
  value: "KI",
}, {
  label: "Kuwait (+965)",
  value: "KW",
}, {
  label: "Kyrgyzstan (+996)",
  value: "KG",
}, {
  label: "Laos (+856)",
  value: "LA",
}, {
  label: "Lativa (+371)",
  value: "LV",
}, {
  label: "Lebanon (+961)",
  value: "LB",
}, {
  label: "Lesotho (+266)",
  value: "LS",
}, {
  label: "Liberia (+231)",
  value: "LR",
}, {
  label: "Libya (+218)",
  value: "LY",
}, {
  label: "Liechtenstein (+423)",
  value: "LI",
}, {
  label: "Lithuanua (+370)",
  value: "LT",
}, {
  label: "Luxembourg (+352)",
  value: "LU",
}, {
  label: "Macau (+853)",
  value: "MO",
}, {
  label: "Macedonia (+389)",
  value: "MK",
}, {
  label: "Madagascar (+261)",
  value: "MG",
}, {
  label: "Malawi (+265)",
  value: "MW",
}, {
  label: "Malaysia (+60)",
  value: "MY",
}, {
  label: "Maldives (+960)",
  value: "MV",
}, {
  label: "Mali (+223)",
  value: "ML",
}, {
  label: "Malta (+356)",
  value: "MT",
}, {
  label: "Marshall Islands (+692)",
  value: "MH",
}, {
  label: "Martinique (+596)",
  value: "MQ",
}, {
  label: "Mauritania (+222)",
  value: "MR",
}, {
  label: "Mauritius (+230)",
  value: "MU",
}, {
  label: "Mayotte (+262)",
  value: "YT",
}, {
  label: "Mexico (+52)",
  value: "MX",
}, {
  label: "Moldova (+373)",
  value: "MD",
}, {
  label: "Monaco (+377)",
  value: "MC",
}, {
  label: "Mongolia (+976)",
  value: "MN",
}, {
  label: "Montenegro (+382)",
  value: "ME",
}, {
  label: "Montserrat (+1)",
  value: "MS",
}, {
  label: "Morocco (+212)",
  value: "MA",
}, {
  label: "Mozambique (+258)",
  value: "MZ",
}, {
  label: "Myanmar (+95)",
  value: "MM",
}, {
  label: "Namibia (+264)",
  value: "NA",
}, {
  label: "Nauru (+674)",
  value: "NR",
}, {
  label: "Nepal (+977)",
  value: "NP",
}, {
  label: "Netherlands (+31)",
  value: "NL",
}, {
  label: "New Caledonia (+687)",
  value: "NC",
}, {
  label: "New Zealand (+64)",
  value: "NZ",
}, {
  label: "Nicaragua (+505)",
  value: "NI",
}, {
  label: "Niger (+227)",
  value: "NE",
}, {
  label: "Nigeria (+234)",
  value: "NG",
}, {
  label: "Niue (+683)",
  value: "NU",
}, {
  label: "Norfolk Island (+672)",
  value: "NF",
}, {
  label: "North Korea (+850)",
  value: "KP",
}, {
  label: "Northern Mariana Islands (+1)",
  value: "MP",
}, {
  label: "Norway (+47)",
  value: "NO",
}, {
  label: "Oman (+968)",
  value: "OM",
}, {
  label: "Pakistan (+92)",
  value: "PK",
}, {
  label: "Palau (+680)",
  value: "PW",
}, {
  label: "Palestine (+970)",
  value: "PS",
}, {
  label: "Panama (+507)",
  value: "PA",
}, {
  label: "Papua New Guinea (+675)",
  value: "PG",
}, {
  label: "Paraguay (+595)",
  value: "PY",
}, {
  label: "Peru (+51)",
  value: "PE",
}, {
  label: "Philippines (+63)",
  value: "PH",
}, {
  label: "Poland (+48)",
  value: "PL",
}, {
  label: "Portugal (+351)",
  value: "PT",
}, {
  label: "Puerto Rico (+1)",
  value: "PR",
}, {
  label: "Qatar (+974)",
  value: "QA",
}, {
  label: "Republic of the Congo (+242)",
  value: "CG",
}, {
  label: "Réunion (+262)",
  value: "RE",
}, {
  label: "Romania (+40)",
  value: "RO",
}, {
  label: "Russia (+7)",
  value: "RU",
}, {
  label: "Rwanda (+250)",
  value: "RW",
}, {
  label: "Saint Helena (+290)",
  value: "SH",
}, {
  label: "Saint Kitts and Nevis (+1)",
  value: "KN",
}, {
  label: "Saint Pierre and Miquelon (+508)",
  value: "PM",
}, {
  label: "Saint Vincent and the Grenadines (+1)",
  value: "VC",
}, {
  label: "Samoa (+685)",
  value: "WS",
}, {
  label: "San Marino (+378)",
  value: "SM",
}, {
  label: "Sao Tome and Principe (+239)",
  value: "ST",
}, {
  label: "Saudi Arabia (+966)",
  value: "SA",
}, {
  label: "Senegal (+221)",
  value: "SN",
}, {
  label: "Serbia (+381)",
  value: "RS",
}, {
  label: "Seychelles(+248)",
  value: "SC",
}, {
  label: "Sierra Leone (+232)",
  value: "SL",
}, {
  label: "Singapore (+65)",
  value: "SG",
}, {
  label: "Sint Maarten (+1)",
  value: "SX",
}, {
  label: "Slovakia (+421)",
  value: "SK",
}, {
  label: "Slovenia (+386)",
  value: "SI",
}, {
  label: "Solomon Islands (+677)",
  value: "SB",
}, {
  label: "Somalia (+252)",
  value: "SO",
}, {
  label: "South Africa (+27)",
  value: "ZA",
}, {
  label: "South Korea (+82)",
  value: "KR",
}, {
  label: "South Sudan (+211)",
  value: "SS",
}, {
  label: "Spain (+34)",
  value: "ES",
}, {
  label: "Sri Lanka (+94)",
  value: "LK",
}, {
  label: "St. Lucia (+1)",
  value: "LC",
}, {
  label: "Suriname (+597)",
  value: "SR",
}, {
  label: "Swaziland (+268)",
  value: "SZ",
}, {
  label: "Sweden (+46)",
  value: "SE",
}, {
  label: "Switzerland (+41)",
  value: "CH",
}, {
  label: "Syria (+963)",
  value: "SY",
}, {
  label: "Taiwan (+886)",
  value: "TW",
}, {
  label: "Tajikistan (+992)",
  value: "TJ",
}, {
  label: "Tanzania (+255)",
  value: "TZ",
}, {
  label: "Thailand (+66)",
  value: "TH",
}, {
  label: "The Bahamas (+1)",
  value: "BS",
}, {
  label: "The Gambia (+220)",
  value: "GM",
}, {
  label: "Timor-Leste (+670)",
  value: "TL",
}, {
  label: "Togo (+228)",
  value: "TG",
}, {
  label: "Tokelau (+690)",
  value: "TK",
}, {
  label: "Tonga (+676)",
  value: "TO",
}, {
  label: "Trinidad and Tobago (+1)",
  value: "TT",
}, {
  label: "Tunisia (+216)",
  value: "TN",
}, {
  label: "Turkey (+90)",
  value: "TR",
}, {
  label: "Turkmenistan (+993)",
  value: "TM",
}, {
  label: "Turks and Caicos Islands (+1)",
  value: "TC",
}, {
  label: "Tuvalu (+688)",
  value: "TV",
}, {
  label: "Uganda (+256)",
  value: "UG",
}, {
  label: "Ukraine (+380)",
  value: "UA",
}, {
  label: "United Arab Emirates (+971)",
  value: "AE",
}, {
  label: "United Kingdom (+44)",
  value: "GB",
}, {
  label: "United States (+1)",
  value: "US",
}, {
  label: "Uruguay (+598)",
  value: "UY",
}, {
  label: "US Virgin Islands (+1)",
  value: "VI",
}, {
  label: "Uzbekistan (+998)",
  value: "UZ",
}, {
  label: "Vanuatu (+678)",
  value: "VU",
}, {
  label: "Venezuela (+58)",
  value: "VE",
}, {
  label: "Vuetnam (+84)",
  value: "VN",
}, {
  label: "Wallis and Futuna (+681)",
  value: "WF",
}, {
  label: "Western Sahara (+212)",
  value: "EH",
}, {
  label: "Yemen (+967)",
  value: "YE",
}, {
  label: "Zambia (+260)",
  value: "ZM",
}]

export const countryCodeToName = countryCodeList.reduce((acc, country) => {
  acc[country.value] = country.label.replace(/\s\(\+\d+\)/, "")
  return acc
}, {})

export const categories = [
  { value: "MARKETING", label: "Marketing" },
  { value: "UTILITY", label: "Utility" },
  { value: "AUTHENTICATION", label: "Authentication" },
]

export const qualityScores = [
  { value: "GREEN", label: "Green" },
  { value: "YELLOW", label: "Yellow" },
  { value: "RED", label: "Red" },
  { value: "UNKNOWN", label: "Unknown" },
]

export const statuses = [
  { value: "APPROVED", label: "Approved", color: variables.success },
  { value: "PENDING", label: "Pending", color: variables.warning },
  { value: "LIMIT_EXCEEDED", label: "Limit Exceeded", color: variables.warning },
  { value: "REJECTED", label: "Rejected", color: variables.error },
  { value: "DISABLED", label: "Disabled", color: variables.error },
  { value: "PAUSED", label: "Paused", color: variables.error },
  { value: "IN_APPEAL", label: "In Appeal", color: variables.darkText },
  { value: "DELETED", label: "Deleted", color: variables.darkText },
  { value: "PENDING_DELETION", label: "Pending Deletion", color: variables.lightText },
]

export const i18n = {
  "Pacific/Midway": "Midway Island, Samoa",
  "Pacific/Honolulu": "Hawaii",
  "America/Juneau": "Alaska",
  "America/Dawson": "Pacific Time (US and Canada); Tijuana",
  "America/Boise": "Mountain Time (US and Canada)",
  "America/Chihuahua": "Chihuahua, La Paz, Mazatlan",
  "America/Phoenix": "Arizona",
  "America/Chicago": "Central Time (US and Canada)",
  "America/Regina": "Saskatchewan",
  "America/Mexico_City": "Guadalajara, Mexico City, Monterrey",
  "America/Belize": "Central America",
  "America/Detroit": "Eastern Time (US and Canada)",
  "America/Indiana/Indianapolis": "Indiana (East)",
  "America/Bogota": "Bogota, Lima, Quito",
  "America/Glace_Bay": "Atlantic Time (Canada)",
  "America/Caracas": "Caracas, La Paz",
  "America/Santiago": "Santiago",
  "America/St_Johns": "Newfoundland and Labrador",
  "America/Sao_Paulo": "Brasilia",
  "America/Argentina/Buenos_Aires": "Buenos Aires, Georgetown",
  "America/Godthab": "Greenland",
  "Etc/GMT+2": "Mid-Atlantic",
  "Atlantic/Azores": "Azores",
  "Atlantic/Cape_Verde": "Cape Verde Islands",
  GMT: "Dublin, Edinburgh, Lisbon, London",
  "Africa/Casablanca": "Casablanca, Monrovia",
  "Atlantic/Canary": "Canary Islands",
  "Europe/Belgrade": "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  "Europe/Sarajevo": "Sarajevo, Skopje, Warsaw, Zagreb",
  "Europe/Brussels": "Brussels, Copenhagen, Madrid, Paris",
  "Europe/Amsterdam": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  "Africa/Algiers": "West Central Africa",
  "Europe/Bucharest": "Bucharest",
  "Africa/Cairo": "Cairo",
  "Europe/Helsinki": "Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
  "Europe/Athens": "Athens, Istanbul, Minsk",
  "Asia/Jerusalem": "Jerusalem",
  "Africa/Harare": "Harare, Pretoria",
  "Europe/Moscow": "Moscow, St. Petersburg, Volgograd",
  "Asia/Kuwait": "Kuwait, Riyadh",
  "Africa/Nairobi": "Nairobi",
  "Asia/Baghdad": "Baghdad",
  "Asia/Tehran": "Tehran",
  "Asia/Dubai": "Abu Dhabi, Muscat",
  "Asia/Baku": "Baku, Tbilisi, Yerevan",
  "Asia/Kabul": "Kabul",
  "Asia/Yekaterinburg": "Ekaterinburg",
  "Asia/Karachi": "Islamabad, Karachi, Tashkent",
  "Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
  "Asia/Kathmandu": "Kathmandu",
  "Asia/Dhaka": "Astana, Dhaka",
  "Asia/Colombo": "Sri Jayawardenepura",
  "Asia/Almaty": "Almaty, Novosibirsk",
  "Asia/Rangoon": "Yangon Rangoon",
  "Asia/Bangkok": "Bangkok, Hanoi, Jakarta",
  "Asia/Krasnoyarsk": "Krasnoyarsk",
  "Asia/Shanghai": "Beijing, Chongqing, Hong Kong SAR, Urumqi",
  "Asia/Kuala_Lumpur": "Kuala Lumpur, Singapore",
  "Asia/Taipei": "Taipei",
  "Australia/Perth": "Perth",
  "Asia/Irkutsk": "Irkutsk, Ulaanbaatar",
  "Asia/Seoul": "Seoul",
  "Asia/Tokyo": "Osaka, Sapporo, Tokyo",
  "Asia/Yakutsk": "Yakutsk",
  "Australia/Darwin": "Darwin",
  "Australia/Adelaide": "Adelaide",
  "Australia/Sydney": "Canberra, Melbourne, Sydney",
  "Australia/Brisbane": "Brisbane",
  "Australia/Hobart": "Hobart",
  "Asia/Vladivostok": "Vladivostok",
  "Pacific/Guam": "Guam, Port Moresby",
  "Asia/Magadan": "Magadan, Solomon Islands, New Caledonia",
  "Pacific/Fiji": "Fiji Islands, Kamchatka, Marshall Islands",
  "Pacific/Auckland": "Auckland, Wellington",
  "Pacific/Tongatapu": "Nukualofa",
}

export const validate = (schema) => async (values) => {
  try {
    await schema.validate(values, {
      abortEarly: false,
    })
  } catch (error) {
    const mapYupValidationError = (newError, errorObj = {}) => {
      newError.inner.forEach((e) => {
        if (_.isArray(e.value)) {
          _.set(errorObj, e.path, [e.message])
        } else {
          _.set(errorObj, e.path, e.message)
        }
        if (e.inner.length) {
          mapYupValidationError(e, errorObj)
        }
      })
      return errorObj
    }

    const errorResult = mapYupValidationError(error)
    return errorResult
  }
}

export const PAGES = {
  CREATE: "create",
  LIST: "list",
  TEMPLATE_LIBRARY: "template-library",
  CHOOSE_TEMPLATE_PAGE_MODAL: "choose-template-page-modal",
}

export const MODALS = {
  INITIAL_SCREEN: "initialScreen",
  EDIT_PUSH_MESSAGE: "editPushMessage",
  TEMPLATE_LIBRARY: "templateLibrary",
  CREATE_TEMPLATE: "createMessageTemplate",
  EDIT_MESSAGE_TEMPLATE: "editMessageTemplate",
}

export const INBOX_PRODUCT_EXTENSION_MODALS = {
  SEND_TEMPLATE: "SEND_TEMPLATE",
  CREATE_TEMPLATE: "CREATE_TEMPLATE",
  APPROVED_TEMPLATES: "APPROVED_TEMPLATES",
}

export const INBOX_PRODUCT_MESSAGE_EXTENSION_MODALS = {
  INITIAL_SCREEN: "INITIAL_SCREEN",
  SEND_SINGLE_PRODUCT: "SEND_SINGLE_PRODUCT",
  SEND_MULTI_PRODUCT: "SEND_MULTI_PRODUCT",
  SEND_CATALOG: "SEND_CATALOG",
}

const VARIABLES_IN_CURLY_BRACES_REGEX = /{{\d+}}/g

export function extractUniqueSortedVariablesFromTextAsNums(text) {
  if (!_.isString(text)) {
    console.error("text should be a string")
    return []
  }
  return _.uniq((text.match(VARIABLES_IN_CURLY_BRACES_REGEX) ?? [])
    .map((variablesWrappedInBraces) => variablesWrappedInBraces.slice(2, -2))
    .map((num) => parseInt(num, 10))
    .sort((a, b) => a - b))
}

export function hydrateVariablesIntoText({
  // eslint-disable-next-line no-shadow
  variables = [],
  text,
}) {
  if (!_.isArray(variables)) {
    console.error("variables should be an array")
    return text
  }
  if (!_.isString(text)) {
    console.error("text should be a string")
    return ""
  }
  return text.replaceAll(VARIABLES_IN_CURLY_BRACES_REGEX, (match) => {
    const matchAsNum = parseInt(match.slice(2, -2), 10)
    const index = matchAsNum - 1
    if (variables[index] !== undefined && variables[index] !== "") {
      return variables[index]
    }
    return `${match}`
  })
}

export function sendToastMessageToInbox({
  type,
  message,
  options,
}) {
  if (!message) {
    console.error("message is required")
    return
  }
  if (navigator.userAgent !== "ReactSnap") {
    window.parent.postMessage({
      action: "iframeToastNotification",
      meta: {
        toastType: type,
        toastMessage: message,
        toastOptions: options,
      },
    }, "*")
  }
}

export function closeInboxModal() {
  if (navigator.userAgent !== "ReactSnap") {
    window.parent.postMessage("iframeCloseModal", "*")
  }
}

export function changeInboxFolder({ folderId }) {
  if (!folderId) {
    console.error("folderId is required")
    return
  }
  const postObject = {
    action: "iframeChangeFolder",
    meta: {
      folderId,
    },
  }
  if (navigator.userAgent !== "ReactSnap") {
    window.parent.postMessage(postObject, "*")
  }
}
