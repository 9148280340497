import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleUser, faTimes } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import theme from "../../stylesheets/theme"

const SPACING = "8px"
const FONT_SIZE_TITLE = "14px"

const DANGER_TEXT = "#f87171"
const DANGER_BACKGROUND = "#fee2e2"

const SUCCESS_TEXT = "#22c55e"
const SUCCESS_BACKGROUND = "#f0fdf4"

const PILL_HEIGHT = "16x"
const PILL_PADDING_Y = "6px"
const PILL_PADDING_X = "14px"
const PILL_BORDER_RADIUS = "8px"

const ParticipantsContainer = styled.div`
  background-color: #eff6ff;
  padding: ${SPACING};
  border-radius: ${PILL_BORDER_RADIUS};
`

const ParticipantsCount = styled.div`
  color: ${theme.gray5};
  font-size: ${FONT_SIZE_TITLE};
  margin-bottom: 8px;
  font-style: italic;
`

const NoParticipantsMessage = styled.div`
  color: ${theme.gray6};
  font-size: ${FONT_SIZE_TITLE};
  text-align: center;
`

const UserPillContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: ${PILL_HEIGHT};
  padding: ${PILL_PADDING_Y} ${PILL_PADDING_X};
  background-color: ${(props) => {
    if (props.isRemoved) {
      return `${DANGER_BACKGROUND}`
    }
    if (props.isAdded) {
      return `${SUCCESS_BACKGROUND}`
    }
    return theme.white
  }};
  border-radius: ${PILL_BORDER_RADIUS};
`

const UserIcon = styled(FontAwesomeIcon)`
  color: ${theme.gray4};
  font-size: 24px;
`

const PhoneNumber = styled.span`
  color: ${theme.gray6};
  font-size: ${FONT_SIZE_TITLE};
`

const UserPill = ({
  phoneNumber, onPhoneNumberDelete = () => {}, isRemoved, isAdded,
}) => {
  const styles = {
    color: theme.gray4,
  }

  if (isRemoved) {
    styles.color = DANGER_TEXT
  }
  if (isAdded) {
    styles.color = SUCCESS_TEXT
  }
  return (
    <UserPillContainer isRemoved={isRemoved} isAdded={isAdded}>
      <UserIcon icon={faCircleUser} />
      <PhoneNumber>{phoneNumber}</PhoneNumber>
      <FontAwesomeIcon
        icon={faTimes}
        style={{ cursor: "pointer", ...styles }}
        onClick={onPhoneNumberDelete}
      />
    </UserPillContainer>
  )
}

export {
  UserPill,
  ParticipantsContainer,
  ParticipantsCount,
  NoParticipantsMessage,
}
