import React, { useEffect, useState } from "react"
import qs from "query-string"
import _ from "lodash"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleXmark, faUser } from "@fortawesome/free-solid-svg-icons"
import { isPossiblePhoneNumber } from "react-phone-number-input"
import { useUpdateInboxHeader } from "../../hooks/useUpdateInboxHeader"
import TextField from "../../components/Radiate/TextField/TextField"
import theme from "../../stylesheets/theme"
import { ModalBody } from "../../components/Radiate/ThemedModal/ThemedModal"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import CountryCodeSelectField from "../../components/Radiate/CountryCodeSelectField/CountryCodeSelectField"
import { useMutate } from "../../hooks/useMutate"
import { changeInboxFolder, closeInboxModal, sendToastMessageToInbox } from "../../components/util"
import {
  NoParticipantsMessage, ParticipantsContainer, ParticipantsCount, UserPill,
} from "../UpdateGroup/GroupSharedStyles"

const INBOX_URL = process.env.REACT_APP_INBOX_URL
const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH

const SMALL_SPACING = "4px"
const SPACING = "8px"
const LARGE_SPACING = "16px"
const BUTTON_WIDTH = "40px"

const AVATAR_SIZE = "60px"
const GROUP_INFO_HEADER_HEIGHT = "80px"
const FONT_SIZE_TITLE = "14px"
const FONT_SIZE_SUBTEXT = "10px"

const PHONE_INPUT_WIDTH = "60%"
const COUNTRY_CODE_WIDTH = "40%"

const DANGER_TEXT = "#ef4444"

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${SPACING};
  background-color: ${theme.gray02};
`

const AvatarSection = styled.div`
  flex: 0 0 ${AVATAR_SIZE};
  cursor: not-allowed;
`

const GroupDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
`

const AvatarPlaceholder = styled.div`
  width: ${AVATAR_SIZE};
  height: ${AVATAR_SIZE};
  border-radius: 50%;
  background-color: ${theme.gray2};
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconWrapper = styled.div`
  color: white;
  font-size: 24px;
`

const GroupInfoWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: ${GROUP_INFO_HEADER_HEIGHT};
  overflow: auto;
  padding: ${SPACING};
  border-radius: ${SPACING};
  gap: ${SPACING};
`

const MarginWrapper = styled.div`
  margin: ${SPACING};
`

const GroupMembersTitle = styled.div`
  font-weight: bold;
  font-size: ${FONT_SIZE_TITLE};
`

const GroupMembersSubtext = styled.div`
  font-size: ${FONT_SIZE_SUBTEXT};
  color: ${theme.gray4};
`

export const ActionBar = styled.div`
  box-sizing: border-box;
  border-top: 1px solid ${theme.gray1};
  padding: 8px 12px;
  display: flex;
  justify-content: ${(p) => (p.hasErrors ? "space-between" : "flex-end")};
  align-items: center;
  justify-self: flex-end;

  .box-button-container {
    margin-left: 8px;
  }
`

const PhoneInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING};
`

export const PhoneFieldGroup = styled.div`
  display: flex;
  gap: ${SPACING};
  align-items: flex-start;
`

export const CountryCodeWrapper = styled.div`
  width: ${COUNTRY_CODE_WIDTH};
`

export const PhoneWrapper = styled.div`
  width: ${PHONE_INPUT_WIDTH};
`

const DeleteButton = styled.div`
  color: ${theme.error};
  cursor: pointer;
  padding: ${SMALL_SPACING};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${BUTTON_WIDTH};
  height: ${BUTTON_WIDTH};
  
  &:hover {
    opacity: 0.8;
  }
`

const MaxMembersMessage = styled.div`
  color: ${theme.gray5};
  font-size: ${FONT_SIZE_TITLE};
  margin-top: ${SPACING};
  text-align: center;
  font-style: italic;
`

// eslint-disable-next-line no-unused-vars
const CreateGroup = ({ location }) => {
  const query = qs.parse(location.search)
  const channelId = query.channel
  const [signedContext, setSignedContext] = useState(null)
  const [payload, setPayload] = useState(null)
  const [subject, setSubject] = useState("")
  const [description, setDescription] = useState("")
  const [phoneNumbers, setPhoneNumbers] = useState([{ countryCode: null, number: "" }])
  const [phoneErrors, setPhoneErrors] = useState({})
  const createGroup = useMutate({
    url: `${SERVER_API_PATH}/api/group`,
    payload,
    signedContext,
    method: "PUT",
    onSuccess: () => {
      sendToastMessageToInbox({
        message: "Group created successfully, please wait for the group to be created inside inbox and refresh the page",
        type: "success",
        options: {
          duration: 15000,
        },
      })
      closeInboxModal()
      changeInboxFolder({
        folderId: "main",
      })
    },
  })

  const receiveMessage = (event) => {
    const whitelistedOrigins = INBOX_URL.split("||")
    if (!whitelistedOrigins.includes(event.origin)) {
      return
    }
    if (event?.data) {
      if (!_.isEmpty(event.data?.payload)) {
        setPayload(event.data?.payload)
        setSignedContext(event.data?.signedContext)
      }
    }
  }

  useEffect(() => {
    if (navigator.userAgent !== "ReactSnap") {
      window.addEventListener("message", receiveMessage, false)
      window.parent.postMessage("iframeFinishLoading", "*")
    }
    return () => {
      window.removeEventListener("message", receiveMessage, false)
    }
  }, [])

  useUpdateInboxHeader({ title: "Create a group", isGroupsView: true })

  const validatePhoneNumber = (number, countryCode) => {
    if (!number) return { text: "Please enter a phone number" }
    if (!countryCode) return { text: "Please select a country code for this number" }
    const fullNumber = `+${countryCode}${number}`
    if (!isPossiblePhoneNumber(fullNumber)) {
      return { text: "The phone number you've entered appears to be invalid" }
    }
    return null
  }

  const handlePhoneNumberChange = (index, phoneNumber) => {
    const numericValue = phoneNumber.replace(/[^0-9]/g, "")
    const updatedNumbers = [...phoneNumbers]
    updatedNumbers[index] = { ...updatedNumbers[index], number: numericValue }
    setPhoneNumbers(updatedNumbers)
    setPhoneErrors((prev) => ({
      ...prev,
      [index]: null,
    }))
  }

  const handleCountryCodeChange = (index, countryCode) => {
    const updatedNumbers = [...phoneNumbers]
    updatedNumbers[index] = { ...updatedNumbers[index], countryCode }
    setPhoneNumbers(updatedNumbers)
    setPhoneErrors((prev) => ({
      ...prev,
      [index]: null,
    }))
  }

  const addPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, { countryCode: null, number: "" }])
  }

  const removePhoneNumber = (index) => {
    // eslint-disable-next-line no-shadow
    const updatedNumbers = phoneNumbers.filter((_, i) => i !== index)
    setPhoneNumbers(updatedNumbers)
  }

  const handleSubmit = () => {
    const newErrors = {}
    let hasErrors = false

    phoneNumbers.forEach((phone, index) => {
      const error = validatePhoneNumber(phone.number, phone.countryCode?.countryCode)
      if (error) {
        newErrors[index] = error
        hasErrors = true
      }
    })

    setPhoneErrors(newErrors)

    if (!hasErrors) {
      createGroup.mutate({
        channelId,
        subject,
        description,
        participants: phoneNumbers.map((p) => ({ user: `+${p.countryCode?.countryCode}${p.number}` })),
      })
    }
  }

  const totalParticipants = phoneNumbers.filter((p) => p?.number && p?.countryCode?.countryCode).length
  return (
    <FormContainer>
      <ModalBody style={{
        padding: 0, display: "flex", flexDirection: "column", gap: LARGE_SPACING,
      }}
      >
        <MarginWrapper>
          <GroupInfoWrapper>
            <AvatarSection>
              <AvatarPlaceholder>
                <IconWrapper>
                  <FontAwesomeIcon size="lg" icon={faUser} />
                </IconWrapper>
              </AvatarPlaceholder>
            </AvatarSection>
            <GroupDetailsSection>
              <TextField
                placeholder="Group subject (required)"
                useInput
                noBorderStyle
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              <TextField
                placeholder="Group description (optional)"
                useInput
                minRows={3}
                noBorderStyle
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </GroupDetailsSection>
          </GroupInfoWrapper>
        </MarginWrapper>
        <MarginWrapper>
          <ParticipantsContainer>
            <ParticipantsCount>
              {totalParticipants}
              {" "}
              members selected
            </ParticipantsCount>
            <div style={{ display: "flex", flexWrap: "wrap", gap: SPACING }}>
              {(totalParticipants) === 0 ? (
                <NoParticipantsMessage>No participants.</NoParticipantsMessage>
              ) : (
                phoneNumbers.filter((p) => p?.number && p?.countryCode?.countryCode).map((phoneNumber, idx) => (
                  <UserPill
                    key={phoneNumber}
                    phoneNumber={`+${phoneNumbers[idx]?.countryCode?.countryCode}${phoneNumbers[idx]?.number}`}
                    onPhoneNumberDelete={() => removePhoneNumber(idx)}
                  />
                ))
              )}
              {phoneNumbers.length >= 8 && (
                <MaxMembersMessage>
                  You cannot add more members because you have reached the maximum number allowed.
                </MaxMembersMessage>
              )}
            </div>
          </ParticipantsContainer>
        </MarginWrapper>
        <MarginWrapper>
          <div style={{ display: "flex", flexDirection: "column", gap: SMALL_SPACING }}>
            <GroupMembersTitle>Add Group Members</GroupMembersTitle>
            <GroupMembersSubtext>9 members are allowed including you</GroupMembersSubtext>
          </div>
        </MarginWrapper>
        <MarginWrapper>
          <PhoneInputWrapper>
            <GroupMembersTitle>Participants</GroupMembersTitle>
            {phoneNumbers.map((phone, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <PhoneFieldGroup key={index}>
                {phoneNumbers.length > 1 && (
                  <DeleteButton onClick={() => removePhoneNumber(index)}>
                    <FontAwesomeIcon color={theme.dangerText} icon={faCircleXmark} />
                  </DeleteButton>
                )}
                <CountryCodeWrapper>
                  <CountryCodeSelectField
                    value={phone.countryCode}
                    onChange={(countryCode) => handleCountryCodeChange(index, countryCode)}
                    codeOnly
                    generalStyles={{
                      menu: (styles) => ({
                        ...styles,
                        width: "180%",
                        color: "#2e2e2e",
                        zIndex: 999,
                      }),
                      menuList: (styles) => ({
                        ...styles,
                        maxHeight: "200px",
                      }),
                      option: (styles) => ({
                        ...styles,
                        whitespace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        ":hover": {
                          ...styles[":hover"],
                          overflow: "visible",
                        },
                        fontSize: "0.8rem",
                      }),
                      control: (styles) => ({
                        ...styles,
                        borderColor: "hsl(0, 0%, 80%)",
                        fontSize: "0.8rem",
                      }),
                    }}
                  />
                </CountryCodeWrapper>
                <PhoneWrapper>
                  <TextField
                    placeholder="Phone number"
                    useInput
                    value={phone.number}
                    error={phoneErrors[index]}
                    onChange={(e) => {
                      handlePhoneNumberChange(index, e.target.value)
                    }}
                  />
                </PhoneWrapper>
              </PhoneFieldGroup>
            ))}
            <NewBoxButton
              text="Add Phone Number"
              icon="plus"
              onClick={addPhoneNumber}
              disabled={phoneNumbers.length >= 8}
            />
          </PhoneInputWrapper>
        </MarginWrapper>
      </ModalBody>
      <ActionBar hasErrors={createGroup.error}>
        {createGroup.error && (
          <div style={{
            color: DANGER_TEXT,
            fontSize: "0.7rem",
            position: "relative",
            top: "-3px",
          }}
          >
            {createGroup.error}
          </div>
        )}
        <NewBoxButton
          style={{
            position: "relative",
            top: "-3px",
            minWidth: "fit-content",
          }}
          text="Create Group"
          icon="plus"
          primary
          loading={createGroup.loading}
          onClick={handleSubmit}
        />
      </ActionBar>
    </FormContainer>
  )
}
export default CreateGroup
